import './App.css';
import {CreateRoom}  from "./components/Create";
import { Join } from "./components/Join";
import { Room } from "./components/Prvroom";
import { Routes,Route } from "react-router-dom";
import {SignUp,LogIn} from "./components/SignupLogin";
import { Connect } from "./app/chat";
import { Home } from './components/Home';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>}/>
      <Route path='/private/join/:username' element={<Join/>}/>
        <Route path='/private/:username' element={<Room/>}/>
        <Route path='/create/private' element={<CreateRoom/>}/>
        <Route path='/signup' element={<SignUp/>}/>
        <Route path='/login' element={<LogIn/>}/>

      </Routes>
    </div>
  );
}

export default App;

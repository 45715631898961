import React,{useState,useRef,useCallback} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUp=()=>{
    const [user,setUser]=useState({UserName:'',Gmail:'',password:''});
    const [error, setError] = useState(null);

    const nav=useNavigate();

    const handleChange = (e) => {
        setUser({ ...user, [e.target.id]: e.target.value });
      };

      
    const funcSignUp=async(e)=>{
        e.preventDefault();

        const res=await axios.post('https://server.arnonline.xyz/signup', user,{withCredentials: true}).then(()=>{nav('/')}).catch(()=>{
            setUser({
                UserName:"",
                Gmail:"",
                password:""
            })
            nav("/signup");
            setError('User already exists.');
        });
    }

    return(
        
        <form onSubmit={funcSignUp}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <fieldset>
            <legend> SignUp </legend>
            UserName: <input type="text" value={user.UserName} id="UserName"
            required onChange={handleChange}
            />
            <br/>
            Gmail: <input type="email" value={user.Gmail} id="Gmail" 
            required onChange={handleChange}/>
            <br/>
            Password: <input value={user.password} type="password" id="password" required 
            onChange={handleChange}/>
            <br/>
            <button type="submit">Signup</button>
            </fieldset>
            Already signup?<a href="/login">login</a>
        </form>
    );
}

const LogIn=()=>{
    const [user,setUser]=useState({UserName:'',password:''});
    const [error, setError] = useState(null);
    const nav=useNavigate();
    const handleChange = (e) => {
        setUser({ ...user, [e.target.id]: e.target.value });
      };

    const funcLogin=async(e)=>{
        e.preventDefault();

        await axios.post("https://server.arnonline.xyz/login", user, {withCredentials: "include"}).then(()=>{
            nav("/");
        }).catch(()=>{
            setUser({
                UserName:"",
                password:""
            })
            nav("/login");
            setError("Username or password is not valid")
        });
    }


return(
<form onSubmit={funcLogin}>
            <fieldset>
            <legend> LogIn </legend>
            UserName: <input type="text" value={user.UserName} id="UserName"
            required onChange={handleChange}
            />
            <br/>
            Password: <input value={user.password} type="password" id="password" required 
            onChange={handleChange}/>
            <br/>
            <button type="submit">LogIn</button>
            </fieldset>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
);
}

export {SignUp,LogIn};
import React,{useState,useEffect,useRef} from "react";
import axios from "axios";
import { useNavigate,useParams } from "react-router-dom";
import { JoinRoom,Msg_Room,useServermsgroom } from "../app/chat";
// import  Cookies from "js-cookie";
export const Room=()=>{
    axios.defaults.withCredentials=true;
    const nav=useNavigate();
    const msg=useServermsgroom();
    const divRef=useRef();
    const chBox=useRef();
    const [me,setMe]=useState("");
    const [chat,setChat]=useState("");

    const {username}=useParams();
    useEffect(()=>{
        const verification= async()=>{
        try {
            let api = await axios.get("https://server.arnonline.xyz/verify");
            setMe(api.data.UserName);
            } 
            catch (error) {
            nav("/signup");
            }
        };
        verification();

        axios.get("https://server.arnonline.xyz/verifyPrvRoom")
        .then(()=>{
            JoinRoom(username);})
        .catch(()=>{nav(`/private/join/${username}`)});
        
    },[])

    useEffect(()=>{

    const senderName=document.createElement('div')
    if(msg.user!="")
    senderName.textContent = (msg.user === me ? "Me" : msg.user);
    
    const div = document.createElement('div');
    div.textContent = msg.msg;
    senderName.appendChild(div);
    
    chBox.current.appendChild(senderName);
    },[msg]);

    const send=(e)=>{
        e.preventDefault();
        Msg_Room(chat,username);
        setChat("");
    }
    return(
        <div id="container">
        <div id="chatBox" ref={chBox}>
        <div ref={divRef}></div>
        </div>
        <form onSubmit={send}>
        <input type="text" value={chat} onChange={(e)=>{setChat(e.target.value)}}/>
        <button type="submit">send</button>
        </form>
        </div>
    );
}
import React,{useState,useEffect,useMemo} from "react";
import { io } from "socket.io-client";

const socket=io("https://server.arnonline.xyz",{
    withCredentials: true,
    // path:"/",
    transports: ['websocket'],
});
export const Connect=()=>{
    socket.on('connect', () => {
        console.log('Connected to server with id:', socket.id);
    });
}

export const JoinRoom=(room)=>{
    socket.emit("join",room);
}

export const Msg_Room=(msg,room)=>{
    socket.emit("msg_room",{msg,room});
}

export const useServermsgroom = () => {
    const [message, setMessage] = useState({
      msg:"",
      user:"",
    });
  
      socket.on("server_msg_room", ({msg,user})=>{
        setMessage({
         msg: msg,
         user: user});
      });
  
    return message;
  }
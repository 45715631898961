import React,{useState,useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {  } from "../app/chat";

export const CreateRoom=()=>{

    const [room,setRoom]=useState({UserName:"",password:""});
    const [username,setUser]=useState("");

    const nav=useNavigate();
    axios.defaults.withCredentials=true;

    useEffect(()=>{
        const verification= async()=>{
            // let api=await axios.get("https://social-i2v1.onrender.com/verify").catch(()=>{nav("/signup")});
            // setUser(api.data.UserName);
            try {
                let api = await axios.get("https://server.arnonline.xyz/verify");
                setUser(api.data.UserName);
            } catch (error) {
                nav("/signup");
            }
        };
        verification();
    },[])

    useEffect(()=>{
        setRoom({...room,UserName:username})
    },[username])

    const handleChange = (e) => {
        setRoom({ ...room, [e.target.id]: e.target.value });
      };

    const call=async(e)=>{
    e.preventDefault();

    await axios.post("https://server.arnonline.xyz/create/private",room)
    .then(()=>{nav(`/private/${room.UserName}`)}).
    catch(()=>{
        setRoom({UserName:"",password:""});
        nav("/create/private");
    });
    }

    return (

        <form onSubmit={call}>
        <fieldset>
        <legend>Create Room: </legend>
        <div>{username}</div>
            Key: <input type="password" id="password"
            value={room.password} onChange={handleChange}/>
            <br></br>
            <button type="submit">Create</button>
            </fieldset>
        </form>
    );
};

import React,{useState,useEffect} from "react";
import axios from "axios";
import { useNavigate,useParams } from "react-router-dom";

export const Join=()=>{

    const [room,setRoom]=useState("");
    const {username} =useParams()
    const nav=useNavigate();
    axios.defaults.withCredentials=true;

    useEffect(()=>{
        
        axios.get("https://server.arnonline.xyz/verify").catch(()=>{nav("/signup")});
        
    },[])

    const call=async(e)=>{
    e.preventDefault();

    await axios.post(`https://server.arnonline.xyz/private/join/${username}`,{room})
    .then(()=>{

        nav(`/private/${username}`)}).
    catch(()=>{
        setRoom("");
        nav(`/private/join/${username}`);
    });
    }

    return (

        <form onSubmit={call}>
        <fieldset>
        <legend>Join Room: </legend>
        <div>{username}</div>
            Key: <input type="password"
            value={room} onChange={(e)=>{setRoom(e.target.value)}}/>
            <br></br>
            <button type="submit">Join</button>
            </fieldset>
        </form>
    );
};
